<template>
  <nav class="container mx-auto p-8 flex items-center justify-end gap-2 transition-colors duration-500 relative">
    <slot />

    <button @click.stop="isOpen = !isOpen" class="lg:hidden" ref="menuToggleRef">
      <svg v-if="isOpen" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
        stroke="currentColor" class="size-8 x-mark">
        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
        stroke="currentColor" class="size-8 hamburger">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
      </svg>
    </button>

    <ul
      class="font-bold text-xl transition-transform duration-100 gap-8 lg:flex absolute lg:static lg:translate-y-0 translate-y-full lg:mb-0 mb-4 lg:mr-0 mr-8 right-0 bottom-0 lg:p-0 p-4 bg-white lg:bg-transparent flex-col lg:flex-row z-20"
      :class="isOpen ? 'sm:flex' : 'hidden'">
      <li class="tracking-widest">
        <a href="/">Hem</a>
      </li>
      <li class="tracking-widest">
        <a href="/om-oss">Om oss</a>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from "vue";

const isOpen = ref(false);
const menuToggleRef = ref<Element | null>(null);

function clickOutside(event: MouseEvent) {
  const targetElement = event.target as HTMLElement;

  if (!menuToggleRef.value?.contains(targetElement)) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.body.addEventListener('click', clickOutside)
})

onBeforeUnmount(() => {
  document.body.removeEventListener('click', clickOutside)
})
</script>
